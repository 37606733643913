import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 42 144"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "m38.496 0 3.37 1.241L3.369 143.505l-3.37-1.242zM38.793 33.505l1.88.692-24.99 92.346-1.879-.692z"
    }, null, -1)
  ])))
}
export default { render: render }