

<script setup lang="ts">
import IconLogo from '~/assets/svgs/crossarea_footer.svg?component'
import DividerFooter from '~/assets/svgs/divider_footer.svg?component'
import queryNavigation from '~~/graphql/queries/Navigation/Navigation.query.graphql'
import queryTermsEntries from '~~/graphql/queries/Terms/TermsEntries.query.graphql'

const {
  data: footerResult,
  pending: footerLoading,
  error: footerError,
} = await useLazyAsyncQuery(queryNavigation, {
  navHandle: 'footer',
})
const filteredFooter = computed(() => footerResult.value?.nav.tree ?? [])

const {
  data: navResult,
  pending: navLoading,
  error,
} = await useAsyncQuery(queryNavigation, {
  navHandle: 'secondary_footer',
})
const filteredNav = computed(() => navResult.value?.nav.tree ?? [])

const {
  data: termsData,
  pending: termsLoading,
  error: termsError,
} = await useAsyncQuery(queryTermsEntries, {
  name: ['ordering_knowledge'],
})
const filteredTerms = computed(() => termsData.value?.terms.data ?? [])
</script>

<template>
  <footer class="relative bg-dark text-white dark:bg-dark dark:text-white">
    <div class="container mx-auto py-10 lg:py-16">
      <section class="relative flex flex-col gap-10">
        <div
          class="grid grid-cols-4 gap-6 md:grid-cols-6 lg:grid-cols-12 lg:gap-10"
        >
          <div
            class="col-span-4 flex flex-wrap items-center gap-10 md:col-span-6 md:col-start-1 md:justify-between md:gap-10 lg:col-span-12 lg:mb-5 lg:flex-nowrap"
          >
            <NuxtLink
              to="/"
              class="block items-center py-5"
              aria-label="Back to the startpage"
            >
              <IconLogo class="w-[200px]" />
            </NuxtLink>
            <NuxtLink to="/" class="items-center">
              <SvgoUlLogoWhite
                class="h-[115px] text-white"
                aria-label="Leipzig University Website"
              />
            </NuxtLink>
            <DividerFooter class="hidden h-[144px] lg:block" />
            <div
              class="max-w-[450px] font-sans text-[18px] font-[500] leading-[25px]"
            >
              CrossArea. Verband für Transregionale Studien, Vergleichende Area
              Studies und Global Studies e.V.
            </div>
            <hr
              class="block w-full border-t border-white text-white lg:hidden"
            >
          </div>
          <ul
            class="relative col-span-4 hidden place-items-start gap-10 after:col-span-12 after:w-full after:border-b after:border-white md:col-span-6 lg:col-span-12 lg:grid lg:grid-cols-12"
          >
            <li
              v-for="tree in filteredFooter"
              :key="tree.page.slug"
              class="col-span-3 flex flex-col"
            >
              <div
                class="text-sans mb-5 w-full text-[24px] font-[300] uppercase leading-[30px] text-white"
              >
                {{ tree.page.title }}
              </div>
              <ul v-if="tree.children.length > 0" class="flex flex-col gap-5">
                <li
                  v-for="child in tree.children"
                  :key="child.page.slug"
                  class="text-sans text-h6 text-white"
                >
                  <NuxtLink
                    :to="
                      child.page.slug ? `/${child.page.slug}` : child.page.url
                    "
                    class="text-sans text-white transition-opacity hover:opacity-50"
                  >
                    {{ child.page.title }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
          <div
            class="col-span-4 grid grid-cols-1 gap-10 md:col-span-6 md:grid-cols-2 md:gap-6 lg:col-span-6 lg:col-start-7 lg:gap-10"
          >
            <div class="col-span-1 font-sans text-[18px] leading-[25px]">
              CrossArea c/o Research Centre Global Dynamics (ReCentGlobe)
            </div>
            <div class="col-span-1">
              Universität Leipzig Nikolaistraße 6-10 Strohsackpassage 04109
              Leipzig
            </div>
            <hr
              class="col-span-1 block border-t border-white text-white lg:hidden"
            >
          </div>
          <div
            class="col-span-4 flex flex-wrap justify-between md:col-span-6 lg:col-span-12 lg:mt-10"
          >
            <p class="order-2 mt-10 lg:order-1 lg:mt-0">
              © 2023 CrossArea
            </p>
            <div
              class="lg:flex-no-wrap order-1 flex flex-wrap gap-10 lg:order-2"
            >
              <ul class="flex flex-col lg:flex-row">
                <li
                  v-for="(secFooter, index) in filteredNav"
                  :key="secFooter.id"
                >
                  
                  <template v-if="index > 0">
                    <div class="mx-2 hidden lg:inline">
                      |
                    </div>
                  </template>
                  <NuxtLink
                    :to="
                      secFooter.page.slug
                        ? `/${secFooter.page.slug}`
                        : secFooter.page.url
                    "
                    class="text-sans text-white transition-opacity hover:opacity-50"
                  >
                    {{ secFooter.page.title }}
                  </NuxtLink>
                </li>
              </ul>
              <NuxtLink class="block" to="mailto:portal@crossarea.org">
                portal@crossarea.org
              </NuxtLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  </footer>
</template>
